.App {
  /* text-align: center; */
}

li {
  list-style: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container {
  padding-top: 130px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-container-item {
  margin: auto;
}

.hk-swf-reflow-content-left::after {
  text-indent: 0;
  white-space: pre;
  display: inline-flex;
  width: 0;
  content: " · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·";
}

.hk-swf-reflow-content-mid {
  display: none;
}

.hk-swf-reflow-content-right {
  float: right;
  background: white;
  position: relative;
  padding-left: 18px;
}